<template>
	<div>
		<div class="bg-w pd20" style="min-height: 800px;">
			
			
			<div class="">
				<div class=" flex space">
					<div>
						<a-button type="primary" icon="plus" @click="addAct()">生成激活码</a-button>
					</div>
				</div>
			</div>
			
			<div class="mt30">
				<div class="wxb-table-gray">
					<a-table rowKey="activation_code_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
						<div class="flex center text-center" slot="app" slot-scope="app,record">
							{{app != null ? app.name : '-'}}
						</div>
						
						<div class="flex center text-center" slot="status" slot-scope="status,record">
							<div v-if="status==1" class="cl-red">已使用</div>
							<div v-if="status==0" class="cl-green">待使用</div>
						</div>
						
						<template slot="action" slot-scope="record">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay" >
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="del(record)">
												<i class="iconfont ft14 icondelete"></i>
												<span class="ml10">删除激活码</span>
											</a>
										</a-menu-item>
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
			
			<div v-if="addActivationCodeVisible">
				<add-activation-code :visible="addActivationCodeVisible" @cancel="cancelAct" @ok="okAct"></add-activation-code>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	import addActivationCode from './components/activationCode/modal/add.vue';
	export default{
		mixins:[listMixin],
		components:{
			addActivationCode,
		},
		data(){
			return{
				loading:false,
				addActivationCodeVisible:false,
				search:{
					status:1,
					keyword:'',
				},
				columns: [
					{title: 'ID',dataIndex: 'activation_code_id',align: 'center',ellipsis: true},
					{title: '适用应用',dataIndex: 'app',align: 'center',scopedSlots: {customRender: 'app'}},
					{title: '激活码',dataIndex: 'activation_code',align: 'center'},
					{title: '状态',dataIndex: 'status',align: 'center',scopedSlots: {customRender: 'status'}},
					{title: '过期时间',dataIndex: 'expire_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods:{
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getActivationCodeList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
			addAct(){
				this.addActivationCodeVisible=true;
			},
			cancelAct(){
				this.addActivationCodeVisible=false;
			},
			okAct(){
				this.addActivationCodeVisible=false;
				this.getLists();
			},
			
			del(record){
				this.$confirm({
					title:'确认删除这个激活码吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/admin/delActivationCode',{
								activation_code_id:record.activation_code_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.dynamic-case-cover-img{
		width: 80px;
		height: 80px;
	}
</style>
